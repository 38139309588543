import axios from 'axios';
import { authUrl } from '../utils/auth-header';
import BASEURL from '../utils/config';

const baseURL = `${BASEURL}/users`;

const login = ({ username, password, type, accessToken, refreshToken }) =>
  axios.post(`${baseURL}/loginAndGet`, {
    username,
    password,
    type,
    token: accessToken,
    refresh_token: refreshToken,
  });

const changePassword = (data) =>
  axios.post(`${baseURL}/change-password${authUrl()}`, data);

const resetPassword = (data, token) =>
  axios.post(`${baseURL}/reset-password?access_token=${token}`, data);

const forgotPassword = (data) => axios.post(`${baseURL}/reset`, data);

const validateAccessToken = (userId, token) =>
  axios.get(`${baseURL}/${userId}/accessTokens/${token}/validate`);

const updateUser = ({ userId, username, email, is_deleted }) => {
  const updateData = {
    username,
    email,
    is_deleted,
  };
  return axios.patch(`${baseURL}/${userId}${authUrl()}`, updateData);
};

const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('intercom-user-hash');
  localStorage.removeItem('teams');
  localStorage.removeItem('practitioner');
};

const findUserById = (userId) =>
  axios.get(`${baseURL}/${userId}${authUrl()}`, {});
const getRole = (userId) =>
  axios.get(`${baseURL}/${userId}/role-assignment${authUrl()}`, {});

const checkSsoEnabled = (email) =>
  axios.post(`${baseURL}/checkSsoEnabled`, {
    email,
  });

export const userService = {
  login,
  logout,
  findUserById,
  updateUser,
  getRole,
  changePassword,
  resetPassword,
  validateAccessToken,
  forgotPassword,
  checkSsoEnabled,
};
