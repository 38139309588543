import React, { Suspense, useEffect, useMemo, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { billingService } from '../services/billing';
import { organizationService } from '../services/organizations';

import { userActions } from '../store/actions/user.actions';

import routes from '../routes';

import { useInitIntercom } from '../hooks/useInitIntercom';

import Loader from '../components/Loader';
import { Banner } from '../components/Banner/Banner';
import SideNav from '../components/SideNav/SideNav';
import useAdminConfig from '../hooks/useAdminConfig';

const Dashboard = function ({ user, logout, history, organizationId }) {
  const { isBillingEnabled, getAdminConfig } = useAdminConfig();
  const [hasPressedClose, setHasPressedClose] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(true);
  const [shouldShowBannerForOrg, setShouldShowBannerForOrg] = useState(false);
  const shouldShowUpsellBanner =
    isBillingEnabled &&
    !hasPressedClose &&
    !hasSubscription &&
    shouldShowBannerForOrg;

  useInitIntercom();

  useEffect(() => {
    getAdminConfig();
  }, []);

  useEffect(() => {
    if (!user) {
      logout();
      history.push('/');
    }
  }, [history, user, logout]);

  const fetchAllSubscriptions = async () => {
    try {
      const [subscriptionResponse, orgResponse] = await Promise.all([
        billingService.getMySubscriptions(),
        organizationId
          ? organizationService.getShowBillingBannerByOrgId(organizationId)
          : Promise.resolve({ data: null }),
      ]);
      const hasSubscriptions = subscriptionResponse.data.length > 0;
      setHasSubscription(hasSubscriptions);
      setShouldShowBannerForOrg(orgResponse.data?.showBanner || false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchAllSubscriptions();
  }, []);

  const routesToRender = useMemo(
    () =>
      routes
        .map((route) => {
          if (route.isBillingRoute && !isBillingEnabled) {
            // Don't show the billingRoute when billing is not enabled
            return null;
          }
          return route;
        })
        .filter((item) => item),
    [isBillingEnabled],
  );

  return (
    <main
      className={`psy-application-container ${
        shouldShowUpsellBanner ? 'with-banner' : ''
      }`}
    >
      {shouldShowUpsellBanner && (
        <Banner setHasPressedClose={setHasPressedClose} />
      )}
      <SideNav routes={routesToRender} isBannerOpen={shouldShowUpsellBanner} />
      <div className='main-container'>
        <Suspense
          fallback={
            <div className='main-container-center'>
              <Loader />
            </div>
          }
        >
          <Switch>
            {routesToRender.map((navOption) => (
              <Route
                key={`route-${navOption.path}`}
                path={navOption.path}
                component={navOption.component}
              />
            ))}
          </Switch>
        </Suspense>
      </div>
    </main>
  );
};

function mapState(state) {
  const { authentication } = state;
  const { user, organizationId } = authentication;
  return { user, organizationId };
}

const mapDispatchToProps = {
  logout: userActions.logout,
  findUserById: userActions.findUserById,
};

export default withTranslation()(
  connect(mapState, mapDispatchToProps)(Dashboard),
);
