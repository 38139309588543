import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getUnixTime } from 'date-fns';

import Intercom from '@intercom/messenger-js-sdk';

export const useInitIntercom = () => {
  const { i18n } = useTranslation();
  const { practitioner, foundUser, roles, organizationId, teams } = useSelector(
    (state) => state.authentication,
  );

  const { organization } = practitioner || {};
  const intercomUserHash = JSON.parse(
    localStorage.getItem('intercom-user-hash'),
  );

  const userRoles = JSON.stringify(roles?.map((role) => role?.roleName));

  useEffect(() => {
    Intercom({
      app_id: 'kcdhj11x',
      user_id: foundUser?.id,
      user_hash: intercomUserHash,
      email: foundUser?.email,
      name: foundUser?.username,
      created_at: getUnixTime(new Date(foundUser?.created)),
      hide_default_launcher: true,
      company: {
        ...(organizationId && { company_id: organizationId }),
        name: organization?.name,
      },
      language_override: i18n.language,
      preferred_language: i18n.language,
      team_Id: teams?.[0],
      user_roles: userRoles,
    });
  }, []);
};
