import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { EMERGIS_ORGANIZATION_ID } from '../utils/config';

// 599017 - acceptance
// 593877 - localhost
const DASHBOARD_TOUR_EN = process.env.REACT_APP_EN_TOUR_ID || 599914;
const DASHBOARD_TOUR_NL = process.env.REACT_APP_NL_TOUR_ID || 601866;
const DASHBOARD_TOUR_DE = process.env.REACT_APP_DE_TOUR_ID || 601867;

export const useIntercomTour = () => {
  const { i18n } = useTranslation();
  const { organizationId } = useSelector((state) => state.authentication);

  const tourId = useMemo(() => {
    if (EMERGIS_ORGANIZATION_ID === organizationId)
      switch (i18n.language) {
        case 'en':
          return DASHBOARD_TOUR_EN;
        case 'nl':
          return DASHBOARD_TOUR_NL;
        case 'de':
          return DASHBOARD_TOUR_DE;

        default:
          return null;
      }
  }, [i18n.language, organizationId]);

  return tourId;
};
