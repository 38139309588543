import React, { useMemo, useState } from 'react';
import { Badge, Box, Typography, useMediaQuery } from '@mui/material';

import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HelpOutlineOutlined } from '@mui/icons-material/';

import {
  show,
  onHide,
  onShow,
  onUnreadCountChange,
  startTour,
} from '@intercom/messenger-js-sdk';

import { classNames } from '../../utils';
import { history } from '../../utils/history';
import { EMERGIS_ORGANIZATION_ID } from '../../utils/config';

import { useIntercomTour } from '../../hooks/useIntercomTour';

import { userActions } from '../../store/actions/user.actions';

import { ReactComponent as LogoIcon } from '../../shared/assets/icons/logo.svg';
import { ReactComponent as HomeIcon } from '../../shared/assets/icons/sidenav/home.svg';
import { ReactComponent as TeamsIcon } from '../../shared/assets/icons/sidenav/teams.svg';
import { ReactComponent as ReportIcon } from '../../shared/assets/icons/sidenav/report.svg';
import { ReactComponent as LogoutIcon } from '../../shared/assets/icons/sidenav/log-out.svg';
import { ReactComponent as DevicesIcon } from '../../shared/assets/icons/sidenav/devices.svg';
import { ReactComponent as ClientsIcon } from '../../shared/assets/icons/sidenav/clients.svg';
import { ReactComponent as SessionIcon } from '../../shared/assets/icons/sidenav/session.svg';
import { ReactComponent as SettingsIcon } from '../../shared/assets/icons/sidenav/settings.svg';
import { ReactComponent as TreatmentIcon } from '../../shared/assets/icons/sidenav/treatment.svg';
import { ReactComponent as BillingIcon } from '../../shared/assets/icons/sidenav/billing-card.svg';
import { ReactComponent as RemoteViewIcon } from '../../shared/assets/icons/sidenav/remote-view.svg';
import { ReactComponent as VideoLibraryIcon } from '../../shared/assets/icons/sidenav/video-library.svg';
import { ReactComponent as OrganizationsIcon } from '../../shared/assets/icons/sidenav/organizations.svg';
import { ReactComponent as PractitionersIcon } from '../../shared/assets/icons/sidenav/practitioners.svg';
import { ReactComponent as AdminSettingsIcon } from '../../shared/assets/icons/sidenav/admin-settings.svg';

import LanguageSwitcher from './LanguageSwitcher/LanguageSwitcher';
import { sideNavStyle } from './style';
import { ButtonUi } from '../UI/Button/Button';

const SideNav = ({
  i18n,
  authentication: {
    userIsAdmin,
    roles,
    foundUser,
    practitioner,
    organizationId,
  },
  logout,
  routes,
  isBannerOpen,
}) => {
  const isLaptop = useMediaQuery('(max-width: 1024px)');
  const { psySideNav } = sideNavStyle;
  const [collapsed, setCollapsed] = useState(false);

  // TODO: uncoment on intercom chat integration
  // const [intercomChatActive, setIntercomChatActive] = useState(false);
  // const [intercomUnreadMessagesCount, setIntercomUnreadMessagesCount] =
  //   useState(0);

  const collapse = () => {
    setCollapsed(!collapsed);
  };

  const userOrganization = practitioner
    ? practitioner?.organization?.name
    : roles?.[0]?.organization?.name;

  /**
   * Gets nav option icon
   * @param navOption navigation option
   * @returns Icon svgComponent
   */
  const getNavOptionIcon = (navOption) => {
    switch (navOption.icon) {
      case 'home':
        return <HomeIcon className='nav-option-icon home' />;
      case 'organizations':
        return <OrganizationsIcon className='nav-option-icon organizations' />;
      case 'teams':
        return <TeamsIcon className='nav-option-icon teams' />;
      case 'practitioners':
        return <PractitionersIcon className='nav-option-icon prationers' />;
      case 'devices':
        return <DevicesIcon className='nav-option-icon devices' />;
      case 'clients':
        return <ClientsIcon className='nav-option-icon clients' />;
      case 'treatment':
        return <TreatmentIcon className='nav-option-icon treatment' />;
      case 'session':
        return <SessionIcon className='nav-option-icon session' />;
      case 'remote-view':
        return <RemoteViewIcon className='nav-option-icon remote-view' />;
      case 'billing-card':
        return <BillingIcon className='nav-option-icon billing' />;
      case 'admin-settings':
        return <AdminSettingsIcon className='nav-option-icon admin' />;
    }
  };

  /**
   * Check routes visability
   * @param navOption navigation option
   * @returns visible navigation routes
   */
  const canSeeRoute = (navOption) => {
    const { allowedRoles, isAdmin, hide, label } = navOption;
    if (hide) {
      return false;
    }
    if (userIsAdmin) {
      return (roles || [])
        .map(({ roleName }) => allowedRoles.includes(roleName))
        .includes(true);
    }
    if (allowedRoles) {
      return (roles || [])
        .map(({ roleName }) => allowedRoles.includes(roleName))
        .includes(true);
    }
    if (userIsAdmin && label !== 'routes.clients') {
      return true;
    }
    if (isAdmin && !userIsAdmin) {
      return false;
    }
    return null;
  };

  const helpCenterSideNav = useMemo(() => {
    if (i18n.language !== 'nl')
      return (
        <li className='psy-side-nav__option'>
          <a
            className='psy-side-nav__option-wrapper'
            href='mailto: support@psylaris.com'
            target='_blank'
            rel='noreferrer'
          >
            <ReportIcon className='nav-option-icon report' />
            <Typography className='nav-caption' variant='caption'>
              {i18n.t('feedback')}
            </Typography>
          </a>
        </li>
      );
    return (
      <li className='psy-side-nav__option'>
        <NavLink
          className='psy-side-nav__option-wrapper'
          activeClassName='is-active'
          to='/admin/help-center'
        >
          <ReportIcon className='nav-option-icon report' />
          <Typography className='nav-caption' variant='caption'>
            {i18n.t('routes.help-center')}
          </Typography>
        </NavLink>
      </li>
    );
  }, [i18n.language]);

  // TODO: uncoment on intercom chat integration
  // onUnreadCountChange((unreadCount) => {
  //   console.log(`[unreadCount]: `, unreadCount);
  //   if (unreadCount !== intercomUnreadMessagesCount)
  //     setIntercomUnreadMessagesCount(unreadCount);
  // });

  // onShow(() => setIntercomChatActive(true));

  // onHide(() => setIntercomChatActive(false));

  const intercomTourId = useIntercomTour();

  const onTourStart = () => {
    if (intercomTourId) startTour(intercomTourId);
  };

  const { username, roleName } = foundUser || {
    username: '',
    roleName: '',
  };
  const roleNameToShow = userIsAdmin
    ? 'Admin'
    : practitioner
    ? i18n.t('roles.therapist')
    : roleName;
  const userName = `${username}`;

  const menuClassMods = {
    long: routes.filter((navOption) => canSeeRoute(navOption)).length >= 10,
    'banner-open': isBannerOpen,
    'psy-side-nav__small': collapsed,
    'psy-side-nav': !collapsed,
  };

  return (
    <Box
      component='nav'
      sx={psySideNav}
      id='side-nav'
      className={classNames('', { ...menuClassMods })}
    >
      <Box className='psy-side-nav__header'>
        <Box className='psy-side-nav__header-container '>
          <Link className='logo-href' to='/admin/dashboard'>
            <LogoIcon className='logo' />
            <Typography className='logo-title' variant='h4'>
              {i18n.t('page-title')}
            </Typography>
          </Link>
          <button
            type='button'
            onClick={collapse}
            className={classNames('chevron-button', { collapsed }, [])}
          >
            <FontAwesomeIcon icon='chevron-left' />
          </button>
        </Box>
      </Box>
      <Box component='ul'>
        {routes.map((navOption) => {
          if (!canSeeRoute(navOption)) {
            return '';
          }
          return (
            <li className='psy-side-nav__option' key={navOption.path}>
              <NavLink
                className='psy-side-nav__option-wrapper'
                activeClassName='is-active'
                to={navOption.path}
              >
                {getNavOptionIcon(navOption)}
                <Typography className='nav-caption' variant='caption'>
                  {i18n.t(navOption.label)}
                </Typography>
              </NavLink>
            </li>
          );
        })}
        <li className='psy-side-nav__option'>
          <NavLink
            className='psy-side-nav__option-wrapper'
            activeClassName='is-active'
            to='/admin/remote-view'
          >
            <RemoteViewIcon className='nav-option-icon remote-view' />
            <Typography className='nav-caption' variant='caption'>
              {i18n.t('buttons.remote-view')}
            </Typography>
          </NavLink>
        </li>
        <li className='psy-side-nav__option'>
          <a
            className='psy-side-nav__option-wrapper'
            href={`https://storage.googleapis.com/psylaris-assets/Promotion/${i18n.language}-psylaris-360-catalogus.pdf`}
            target='_blank'
            rel='noreferrer'
          >
            <VideoLibraryIcon className='nav-option-icon video-library' />
            <Typography className='nav-caption' variant='caption'>
              {i18n.t('routes.video-library')}
            </Typography>
          </a>
        </li>
        {helpCenterSideNav}
      </Box>
      <Box className='psy-side-nav__option login-container'>
        {organizationId === EMERGIS_ORGANIZATION_ID && (
          <Box className='psy-side-nav__option button-option-wrapper'>
            <ButtonUi
              tooltipTitle={
                collapsed || isLaptop ? i18n.t('buttons.start_tour') : ''
              }
              tooltipPlacement='top'
              className='start-tour'
              onClickHandler={onTourStart}
              startIcon={<HelpOutlineOutlined className='nav-option-icon' />}
            >
              {i18n.t('buttons.start_tour')}
            </ButtonUi>
          </Box>
        )}

        <Box className='psy-side-nav__option-wrapper no-click'>
          <Box className='user-wrapper'>
            <Box className='user-name'>
              <Typography variant='h6' className='name-label'>
                {userName}
              </Typography>
              <Typography variant='body2' className='role-label'>
                {roleNameToShow}
              </Typography>
              {userOrganization && (
                <Typography variant='body2' className='org-label'>
                  {userOrganization}
                </Typography>
              )}
            </Box>
          </Box>
          <Box className='actions'>
            <LanguageSwitcher />
            <button
              type='button'
              className='icon-btn'
              onClick={() => history.push('/admin/account-management')}
            >
              <SettingsIcon className='settings' />
            </button>
            {/* <Badge
              badgeContent={intercomUnreadMessagesCount}
              overlap='circular'
              className='intercom-unread-badge'
            >
              <button
                type='button'
                className={classNames('icon-btn', {
                  'chat-open': intercomChatActive,
                })}
                onClick={() => show()}
              >
                <HelpOutlineOutlined className='chat' />
              </button>
            </Badge> */}
            <button type='button' className='icon-btn' onClick={() => logout()}>
              <LogoutIcon className='logout-button' />
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  logout: userActions.logout,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SideNav),
);
