import { userConstants } from '../constants/user.constants';
import { history } from '../../utils/history';

let user = null;
let foundUser = null;
let organizationId = null;
let foundPractitioner = null;
let foundTeams = [];
const roleOrganizationIds = [];

try {
  user = JSON.parse(localStorage.getItem('token'));
  foundTeams = JSON.parse(localStorage.getItem('teams')) || [];
  foundUser = JSON.parse(localStorage.getItem('user')) || {};
  const localPractitioner = localStorage.getItem('practitioner');

  if (localPractitioner && localPractitioner !== 'undefined') {
    foundPractitioner = JSON.parse(localPractitioner);
    organizationId = foundPractitioner.organizationId;
  }
} catch (e) {
  localStorage.clear();
  console.error("[AUTHENTICATION ERROR]: couldn't parse the localstorage data. CLEARED LOCAL STORAGE");
  history.push('/login');
}

const isAdmin = (role) => {
  const adminRoles = ['super-admin'];
  return adminRoles.includes(role);
};

const isDeviceAdmin = (roles) => {
  const deviceAdminRoles = ['device-admin'];
  return (roles || []).map((item) => item.roleName).some((item) => deviceAdminRoles.includes(item));
};

const isDeviceManager = (roles) => {
  const deviceManagerRoles = ['device-manager'];
  return (roles || [])
    .map((item) => item.roleName)
    .some((item) => deviceManagerRoles.includes(item));
};

const isLogisticsAdmin = (roles) => {
  const logisticsAdminRoles = ['logistics-admin'];
  return (roles || [])
    .map((item) => item.roleName)
    .some((item) => logisticsAdminRoles.includes(item));
};

const canManageTeams = (role) => {
  const adminRoles = ['super-admin', 'organization-manager', 'logistics-admin'];
  return adminRoles.includes(role);
};

const checkManageableOrganization = (roles) =>
  (roles || [])
    .map((role) => {
      const { organizationId, roleName } = role;
      roleOrganizationIds.push(organizationId);
      if (canManageTeams(roleName)) {
        return organizationId;
      }
      return null;
    })
    .filter((item) => item);

const { roleName, 'role-assignment': roles } = foundUser || {};
const organizationIds = checkManageableOrganization(roles);

const initialState = user
  ? {
      loggedIn: true,
      user,
      foundUser,
      teams: foundTeams,
      practitioner: foundPractitioner,
      loggingIn: false,
      userIsAdmin: isAdmin(roleName),
      isLogisticsAdmin: isLogisticsAdmin(roles),
      isDeviceAdmin: isDeviceAdmin(roles),
      isDeviceManager: isDeviceManager(roles),
      canManageTeams: canManageTeams(roleName),
      manageableOrganizationIds: organizationIds,
      organizationId: organizationId || [...new Set(roleOrganizationIds)][0],
      roles,
      roleName,
    }
  : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS: {
      const { login, user, practitioner, teams, userHash } = action.user;
      localStorage.setItem('token', JSON.stringify(login));
      localStorage.setItem('intercom-user-hash', JSON.stringify(userHash));
      const roles = user?.['role-assignment'] || [];
      const { roleName } = user?.['role-assignment'][0] || {};
      user.roleName = roleName;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('practitioner', JSON.stringify(practitioner));
      localStorage.setItem('teams', JSON.stringify(teams || []));
      const possibleOrganizationIds = roles.map((role) => role.organizationId);
      const possibleOrganizationId =
        foundUser?.organizationId || [...new Set(possibleOrganizationIds)][0];
      return {
        ...state,
        user: login,
        foundUser: user,
        loggingIn: false,
        userIsAdmin: isAdmin(roleName),
        isDeviceAdmin: isDeviceAdmin(roles),
        isDeviceManager: isDeviceManager(roles),
        isLogisticsAdmin: isLogisticsAdmin(roles),
        canManageTeams: canManageTeams(roles),
        manageableOrganizationIds: checkManageableOrganization(roles),
        organizationId: possibleOrganizationId,
        teams,
        practitioner,
        roleName,
        roles,
      };
    }
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
      };
    default:
      return state;
  }
}
