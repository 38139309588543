import React, { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import CustomBreadcrumbs from '../components/CustomBreadcrumbs';

import { practitionerActions } from '../store/actions/practitioner.actions';
import { statisticsService } from '../services/statistics';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useActions } from '../hooks/useActions';

const PractitionerDetailPage = (props) => {
  const { t } = useTranslation();
  const practitionerId = props.match.params.id;
  const { findPractitionerById } = useActions();
  const { selectedPractitioner } = useSelector((state) => state.practitioners);

  const [stats, setStats] = useState();

  const backNav = {
    title: t('practitioners.overview'),
    route: '/admin/practitioners',
  };

  const name = `${selectedPractitioner.firstname} ${selectedPractitioner.lastname}`;

  const counts = useMemo(
    () => stats?.map((item) => parseInt(item.count)),
    [stats],
  );

  const months = useMemo(
    () => stats?.map((item) => `${moment(item.week).format('DD-MM-YYYY')}`),
    [stats],
  );

  const options = {
    chart: {
      id: 'basic-bar',
      background: '#fff',
    },
    xaxis: {
      categories: months || [],
    },
    series: [
      {
        name: t('sessions.graph.weekly'),
        data: counts || [],
      },
    ],
  };

  useEffect(() => {
    if (!practitionerId) return;
    findPractitionerById(practitionerId);
    const filter = `&practitionerId=${practitionerId}`;
    statisticsService.getAllStatistics(filter).then((result) => {
      setStats(result.data);
    });
  }, []);

  return (
    <>
      <div className='title'>
        <CustomBreadcrumbs backNav={backNav} currentTitle={name} />
        <p className='title-description'>
          {t('practitioners.detail-subtitle')}
        </p>{' '}
      </div>
      <div className='practitioner-detail-container'>
        <div className='chart-container'>
          <div className='chart-title'>
            <h3>{t('sessions.graph.title')}</h3>
          </div>
          <Chart
            options={options}
            series={options?.series}
            type='bar'
            height='300'
          />{' '}
        </div>
        <div className='title'>Teams of the practitioner</div>
        <div className='detail-grid'>
          {selectedPractitioner.teams.map((team) => (
            <div key={`${team.id}team-detail`} className='row row-small'>
              <p>
                Team {team.id}: {team.name}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PractitionerDetailPage;
